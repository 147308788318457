'use client'

import React from 'react';
import dynamic from "next/dynamic";

import { cn } from "@/lib/utils"
import { sampleArcs } from "@/components/landing/globeData"

const World = dynamic(() => import("../ui/globe").then(m => m.default), {
  ssr: false,
});

const globeConfig = {
  pointSize: 4,
  globeColor: "#000",
  showAtmosphere: true,
  atmosphereColor: "#1570EF",
  atmosphereAltitude: 0.14,
  emissive: "#000",
  emissiveIntensity: 0.1,
  shininess: 1,
  polygonColor: "#98A2B3",
  ambientLight: "#98A2B3",
  directionalLeftLight: "#ffffff",
  directionalTopLight: "#ffffff",
  pointLight: "#ffffff",
  arcTime: 1000,
  arcLength: 0.9,
  rings: 2,
  maxRings: 3,
  initialPosition: { lat: 28.457523, lng: 77.026344 },
  autoRotate: true,
  autoRotateSpeed: 0.5,
};

const LandingGlobe = ({
  className
}) => {
  return (
    <div
      className={cn(
        "w-full h-full flex justify-center items-center absolute z-10 xs:scale-[1.1]",
        "md:scale-[1.1] xl:scale-[1.3]",
        className
      )}
    >
      <World data={sampleArcs} globeConfig={globeConfig} />
    </div>
  )
}

export default LandingGlobe
