import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/common/hero/hero.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["LottiePlayer"] */ "/app/src/components/common/lottie/player.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landing/landing-globe.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landing/solutions.jsx");
